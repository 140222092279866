<template>
  <v-select
    :v-if="loading"
    v-model="profile"
    name="profile"
    :label="label"
    :items="items"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    @change="profileSelected"
  />
</template>

<script>
import PerfisUsuariosService from '@/services/PerfisUsuariosService';

export default {
  name: 'SelectUserProfile',

  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Perfil'
    },
    selected: {
      type: String,
      default: 'Comum'
    }
  },

  data() {
    return {
      loading: false,
      profile: 'Comum',
      items: []
    };
  },

  mounted() {
    this.profile = this.selected;
    this.getProfiles();
  },

  watch: {
    selected() {
      this.profile = this.selected;
    }
  },

  methods: {
    profileSelected(event) {
      this.$emit('profile:selected', event);
    },

    getProfiles() {

      this.loading = true

      PerfisUsuariosService.getPerfisAtivos()
          .then(({ data }) => {
            data.forEach(value => {
              this.items.push(value.title);  
            })
          })
          .catch((error) => {
            console.error(error);
            this.$toast.error('Erro ao recuperar os perfis de usuários.', '', {
              position: 'topRight'
            });
          })
          .finally( () => {
            this.loading = false;
          });
    }
  }
};
</script>
